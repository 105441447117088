/** @jsx jsx */
import { ICMSBanner } from './types'
import { Link } from 'gatsby'
import ImageWrapper from '~/components/image-wrapper'
import { jsx, Flex, Heading, Box, Image, Text } from 'theme-ui'
import BlockTextRender from '../pageBuilder/components/BlockTextRender'
import { BannerTitle } from './styles'

interface BannerInjectComponentProps {
  banner: ICMSBanner
}
export const BannerInjectComponent = ({ banner }: BannerInjectComponentProps) => {
  return (
    <ImageWrapper ratio={1} center>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {banner.image?.url ? (
          <Link
            to={banner.link || '#'}
            style={{
              position: 'relative',
            }}
          >
            <Image
              className={`${banner.handle}`}
              src={banner.image.url}
              loading="lazy"
              variant="FULLSCREEN"
              alt={banner.name}
            />
          </Link>
        ) : null}
        <Box
          sx={{
            p: 16,
            background: banner.backgroundColor || 'none',
            color: banner.textColor || 'text',
            textAlign: 'center',
            width: '100%',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {banner?.title.length ? (
            <BannerTitle>
              <BlockTextRender content={banner.title} />{' '}
            </BannerTitle>
          ) : null}
          {banner?.description.length ? <BlockTextRender content={banner.description} /> : null}
        </Box>
      </Box>
    </ImageWrapper>
  )
}

export const defaultRenderBanner = (banner: ICMSBanner, key: string) => {
  return <BannerInjectComponent key={key} banner={banner} />
}
