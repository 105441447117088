import { parseCmsData } from '~/componentsV2/pageBuilder/utils'
import { CMSBannerType, IBannerInject, ICMSBanner, IBannerInjectArray } from './types'
import { defaultRenderBanner } from './banner'

export const bannerInjectArray = <T>({
  banners,
  visibility,
  list,
}: IBannerInjectArray<T>): (T | CMSBannerType)[] => {
  const _banners = banners
    .map(o => {
      return parseCmsData<ICMSBanner>(o)
    })
    .filter(o => [visibility, 'all'].includes(o.visibility))

  const _list: (T | CMSBannerType)[] = [...list]

  _banners.forEach(banner => {
    _list.splice(banner.position - 1, 0, {
      ...banner,
      __banner: true,
    })
  })

  return _list
}

export const bannerInject = <T>({
  banners,
  visibility,
  list,
  render,
  renderBanner = defaultRenderBanner,
}: IBannerInject<T>) => {
  const _list = bannerInjectArray({
    banners,
    visibility,
    list,
  })
  return _list.map((item, index) => {
    const _banner = item as CMSBannerType
    if (_banner?.__banner) {
      return renderBanner(_banner, 'banner-' + _banner.id)
    } else {
      return render(item as T, index)
    }
  })
}
